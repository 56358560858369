/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { Fragment, useState } from 'react';
import Button from './Button';

const Modal = ({ text, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button className="cursor-pointer underline hover:text-brand-green-dark" onClick={() => setOpen(true)}>
        {children}
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" open={open} onClose={setOpen}>
          <div className="flex min-h-screen items-start justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block overflow-hidden rounded-xl relative bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button type="button" className="focus:outline-none bg-white text-gray-400 hover:text-gray-500 focus:ring-0 focus:ring-brand focus:ring-offset-0" onClick={() => setOpen(false)}>
                    <span className="sr-only">Schließen</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-brand-gray">
                      {children}
                    </Dialog.Title>
                    <div className="mt-2">{text}</div>
                  </div>
                </div>
                <div className="mt-5 flex flex-col-reverse gap-2 sm:mt-4 sm:flex-row-reverse">
                  <Button text="Schließen" onClick={() => setOpen(false)} type="white" small />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Modal;
